import React, { Component } from "react";
import moment from 'moment';
import { acceptOrDenyNotification, notificationsGroupedByName } from "../../helpers/helper-notifications";
import ModalError from "../modal-error";
import helper from "../../helpers";
import * as helper_error from "../../helpers/helper-error";
import route_helper from "../../route-helper";
import { withRouter } from "react-router";
import notifications_api from '../../api/notifications-api'
import settings from "../../configs/settings";

class NotificationPatientUpdates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_notification_id: undefined,
      show_modal_error: false,
      items_per_batch: 10,
      notifications: []
    };

    this.fetchNotifications = this.fetchNotifications.bind(this);
    this.openPatientUpdates = this.openPatientUpdates.bind(this);
  }

  async componentDidMount() {
    await this.fetchNotifications();
  }

  async fetchNotifications() {
    let includeUnopenedFamilies = false

    try {
      let notifications = await notifications_api.get_clinician_notifications(
        this.props.clinician_id,
        ["pending"],
        settings.clinician_notification_types,
        includeUnopenedFamilies
      );

      this.setState({ notifications: notifications });
    } catch (error) {
      console.log(error);
    }
  }

  async dismiss(notification) {
    // Skip temporarily
    return;
    try {

      let { clinician_id, user_id } = this.props;
      this.setState({ active_notification_id: notification.id });
      await acceptOrDenyNotification(notification.id, clinician_id, user_id, "deny");

      await this.fetchNotifications();

    } catch (error) {
      this.setState({ show_modal_error: true });
      helper_error.log_error(error);
    } finally {
      await helper.delay(1);
      this.setState({ loading: false, active_notification_id: undefined });
    }
  }

  handleShowMoreClick() {
    let { items_per_batch } = this.state;
    items_per_batch += 10;
    this.setState({items_per_batch});
  }

  selectDistinct(notifications, is_read = false) {
    let distinct = [];
    let distinct_family_id = [];
    let filtered_notifications = notifications.filter(n => n.is_read == is_read);
    for(let item of filtered_notifications) {
      if(distinct_family_id.includes(item.from_family_id)) continue;
      distinct_family_id.push(item.from_family_id);
      distinct.push(item);
    }
    return distinct;
  }

  async openPatientUpdates(notification, receiver_proband) {
    let patient_id = receiver_proband.id;
    let url = route_helper.pedigree.pedigree_root.replace(":member_id", patient_id) + "/#patient-updates";

    this.props.history.push('/empty');
    await helper.delay(0.1);
    this.props.history.replace(url);
  }

  async dismissNotifications(){
    let { loading } = this.state
    let { errorMessages } = this.state
    try{

      loading = true
      this.setState({ loading });
      let clinician_id = this.props.clinician_id;
      await notifications_api.post_notification_notification_dismiss_patient_updates({clinician_id});

      await this.fetchNotifications();

    } catch (error) {
      errorMessages = [error.message]
      this.setState({ errorMessages });
      helper_error.log_error(this.constructor.name, error);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    let unread_notifications = notificationsGroupedByName(this.state.notifications, false);
    let read_notifications = notificationsGroupedByName(this.state.notifications, true);
    let { active_notification_id, items_per_batch } = this.state;

    if(this.state.notifications.length == 0) return null;
    let visible_notifications = Object.values(read_notifications).sort((a,b) => moment(b.most_recent_created_at).diff(moment(a.most_recent_created_at))).slice(0, items_per_batch);

    return (
      <div className={"tab-pane fade in active"}>
        <p className="subtitle">
          The following patients have pending updates to their data.
        </p>

        {Object.values(unread_notifications).sort((a,b) => moment(b.most_recent_created_at).diff(moment(a.most_recent_created_at))).map((notification, index) => {
          return <NotificationItem
            key={index}
            notification={notification}
            onDismissClick={() => this.dismiss(notification)}
            onPatientClick={this.openPatientUpdates}
            showDismissButton={false}
            activeNotificationId={active_notification_id}
            clinician_id={this.props.clinician_id}
          />
        })}

        { Object.values(read_notifications).length > 0 &&
          <div className=" margin-four-bottom">
            <a href="#" className="link" onClick={()=> this.dismissNotifications()}>
              Dismiss all
            </a>
          </div>
        }

        {visible_notifications.map((notification, index) => {
          return <NotificationItem
            key={index}
            notification={notification}
            onDismissClick={() => this.dismiss(notification)}
            onPatientClick={this.openPatientUpdates}
            showDismissButton={false}
            activeNotificationId={active_notification_id}
            clinician_id={this.props.clinician_id}
          />
        })}

        { Object.values(read_notifications).length > visible_notifications.length &&
          <div className=" margin-four-bottom">
            <a className="link" onClick={() => this.handleShowMoreClick()}>
              Show More <i className="fa fa-angle-down"></i>
            </a>
          </div>
        }

        {this.state.show_modal_error && (
          <ModalError
            onClose={() => this.setState({ show_modal_error: false })}
          />
        )}

      </div>
    );
  }
}

class NotificationItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receiver_proband: null
    };

    this.fetchReceiverProband = this.fetchReceiverProband.bind(this);
    this.checkClickEvent = this.checkClickEvent.bind(this);
    this.setLocalStorage = this.setLocalStorage.bind(this);
  }

  async componentDidMount() {
    await this.fetchReceiverProband();
  }

  async fetchReceiverProband() {
    try {

      let proband = await notifications_api.get_receiver_proband_for_notifications(
        this.props.clinician_id,
        this.props.notification.id
      );

      this.setState( { receiver_proband: proband });
    } catch (error) {
      console.log(error);
    }
  }

  setLocalStorage() {
    const tokens = sessionStorage.getItem('famgenix_app_tokens');
    const extra_data = sessionStorage.getItem('extra_data');
    localStorage.setItem('famgenix_app_tokens', tokens);
    localStorage.setItem('extra_data', extra_data);
  }

  checkClickEvent(event, pedigree_url, notification, onPatientClick) {
    var isRightMB;

    if ("which" in event.nativeEvent) { // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightMB = event.nativeEvent.which === 3;
    } else if ("button" in event.nativeEvent) {  // IE, Opera
      isRightMB = event.nativeEvent.button === 2;
    }

    const url = process.env.REACT_APP_SITE_URL+pedigree_url;
    if (isRightMB || event.ctrlKey || event.metaKey) {
      // temp set the sessionStorage to locaStorage
      this.setLocalStorage();

      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      onPatientClick(notification, this.state.receiver_proband);
    }
  }

  render() {
    let { notification, onDismissClick, onPatientClick, showDismissButton, activeNotificationId } = this.props;
    let { created_at, updates, most_recent_created_at } = notification;
    let options = {year: 'numeric', month: 'long', day: 'numeric' };
    let name = this.state.receiver_proband
      ? this.state.receiver_proband.first_name +
        " " +
        this.state.receiver_proband.last_name
      : "";

    let created_date = new Date(notification.created_at).toLocaleDateString(undefined, options);
    let created_time = moment(most_recent_created_at).format("H:mm A");

    // Use this to disable dimiss button when clicked
    let isCurrentNotification = activeNotificationId === notification.id;

    let pedigree_url = null;
    if (this.state.receiver_proband) {
      pedigree_url = "/pedigree/" + this.state.receiver_proband.id;
    }

    return (
      <div  className="notif-item">
        { showDismissButton &&
          <a href="#" className="del" onClick={() => onDismissClick()}>
            <i className="fa fa-trash"></i>
          </a>
        }

        <p>
          <a onClick={(e) => this.checkClickEvent(e, pedigree_url, notification, onPatientClick)} style={{color: '#626262'}}>
            <strong>{name}</strong>
            &nbsp;{updates.length > 1 && '(' + updates.length + ')'}
          </a>
        </p>

        <p>
          <span className="date">{created_date}</span>{" "}
          <span className="margin-two-right time">{created_time}</span>
        </p>

        {/* { active_notification_id === notification.id &&
          <div style={{ position: "absolute", right: 10, top: 20 }}>
            <ActivityIndicator loading={true} />
          </div>
        } */}
      </div>
    );
  }
}

export default withRouter(NotificationPatientUpdates);
