import React, { useMemo } from 'react';
import { getMarkerEnd, useStore, getSmoothStepPath, useNodes } from 'reactflow';

import { createHook, createLine, createUUID, getEdgeParams } from './utils';

const FloatingStepEdge = (props) => {
  const id = props.id;
  const source = props.source;
  const target = props.target;
  const arrowHeadType = props.arrowHeadType;
  const markerEndId = props.markerEndId;
  const style = props.style;

  const nodes = useNodes()
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const sourceNode = useMemo(() => nodes.find((n) => n.id === source), [source, nodes]);
  const targetNode = useMemo(() => nodes.find((n) => n.id === target), [target, nodes]);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  // checking the target node we are assuming this is a BottomToChild edge
  let adopted_in = "none";
  if (targetNode.data.profile && targetNode.data.profile.adopted_in) {
    adopted_in = "5";
  }
  let ida = id + "_aa";
  let idb = id + "_bb";
  let intersection = [];
  if (props.data != null)
  {
	  props.data.datastore.updateEdge({"src":sourceNode.id,"target":targetNode.id, "type":"Child", "edgelist":[ida, idb]});
	  intersection = props.data.datastore.getHorizonalLines(tx,sy,tx, ty); // line is the vertical part, so use tx as first param.
  }
  let items = [] ;

  if (intersection.length == 0 )
  {
	items.push(<line key={createUUID()} id={idb} x1={tx} y1={sy} x2={tx} y2={ty} style={style} strokeDasharray={adopted_in}></line>);
  }
  else
  {
	let hooksAndLines = props.data.datastore.splitVerticalLine(tx, sy, ty,intersection);
	let lines = hooksAndLines["lines"];
	for (let i = 0; i < lines.length; i++)
	{
		let line = createLine(lines[i],id, i,adopted_in, style);
		items.push(line);
	}
	let hooks = hooksAndLines["hooks"];
	for (let i = 0; i < hooks.length; i++)
	{
		let pathLine = createHook(hooks[i], id, i,markerEnd,style);
		items.push(pathLine);
	}
  }


  return (
    <g className="react-flow__connection">
      <line key={createUUID()} id={ida} x1={sx} y1={sy} x2={tx} y2={sy} style={style}></line>
      {items}
    </g>
  );
};

export default FloatingStepEdge;
